import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VHover,[(_vm.book.code)?_c(VCard,{staticClass:"d-flex",attrs:{"flat":"","tile":""},on:{"click":function($event){return _vm.openBook(_vm.book.code)}}},[_c(VImg,{staticClass:"grey lighten-2",attrs:{"src":("//cdn.pulib.sk/document/" + (_vm.book.code) + ".jpg")}},[_c(VLayout,{attrs:{"slot":"placeholder","fill-height":"","align-center":"","justify-center":"","ma-0":""},slot:"placeholder"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>

import { mapState, mapGetters } from 'vuex';

export default
{
    data()
    {
        return {
        };
    },
    computed:
    {
        ...mapGetters(
        {
            userToken: 'getUserToken',
            books: 'getBookList'
        }),
        book: function()
        {
            let list = this.books || [];
            let len = list.length || 0;
            let r = this.getRandom(0, len);
            return list[r] || {};
        }
    },
    created()
    {
        const scope = this;
    },
    destroyed()
    {
        const scope = this;
    },
    methods:
    {
        close()
        {
            const scope = this;
            scope.$router.push({name: 'catalog'});
        },
        getRandom(min, max)
        {
            return Math.floor(Math.random() * (max - min) + min);
        }
    }
};
</script>

<template>

    <v-app>
        <v-hover>
            <v-card v-if="book.code" @click="openBook(book.code)" flat tile class="d-flex">
                <v-img :src="`//cdn.pulib.sk/document/${book.code}.jpg`" class="grey lighten-2">
                    <v-layout slot="placeholder" fill-height align-center justify-center ma-0>
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-layout>
                </v-img>
            </v-card>
        </v-hover>
    </v-app>

</template>

